<template>
	<div>
		<el-dialog :title="title" @close="close" :visible.sync="show" :width="width" destroy-on-close center>
			<slot name="title"></slot>
			<slot name="content"></slot>
			<slot name="footer"></slot>
		</el-dialog>
	</div>

	<!-- <div class="modal-content">
  <slot>这是个弹框</slot>
  <div class="footer">
    <button @click="close">close</button>
    <button @click="confirm">confirm</button>
  </div>
</div> -->
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: ''
			},
			width: {
				type: String,
				default: ''
			},
			visible: {
				type: Boolean,
				default: false
			}
			// ["title", "width", "visible"]
		},
		// watch: {
		// 	visible(newVal, oldVal) {
		// 		if (newVal == false) {
		// 			this.show = true
		// 		} else {
		// 			this.show = newVal
		// 		}

		// 		console.log(newVal, oldVal, this.show)
		// 	}
		// },
		data() {
			return {
				show: false
			};
		},
		methods:{
			open(){
				console.log('open')
				this.show = true
			},
			close(){
				console.log('close')
				this.show = false
				this.$emit('closeDialog')
			}
			
		}
	};
</script>

<style lang="less" scoped>
	// .el-dialog__header {
	// 	padding: 20px 20px 10px;
	// 	display: none;
	// }

	.el-dialog__body {
		padding: 0px !important;
		// border: 1PX solid red;
	}
	::v-deep .el-dialog .el-dialog--center{
		max-height: 100%;
		width: 100vw;
	}

	::v-deep .el-dialog {
		background: @popupBackgroundColor  !important;
		border: 1px solid @popupCommonBorderColor;
		// height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	::v-deep .el-dialog__body{
		padding: 0px !important;
	}
	::v-deep .el-dialog__header{
		padding: 0px !important;
	}
	// .el-dialog{
	// 	background: #222 !important;
	// }
</style>
