<template>
	<div>
		<div class="main">
			<div class="content" v-for="(item,index) in infoObj.bankcard" :key="index">
				<div class="top">
					<div class="left">
						<!-- <img class="icon" :src="item.icon" alt=""> -->
						<Icon
						  :name="item.get_bank ? item.get_bank.bank_icon : ''"
						  theme-color="collectYellow"
						  width="28"
						  height="28"
						></Icon>
						<div class="desc">
							<div class="title">{{item.get_bank ? item.get_bank.bank_name : ''}}</div>
							<div class="type">{{item.type}}</div>
						</div>
					</div>

					<div @click="delBank(item.id)" class="relieve-bind">{{$t('removebind')}}</div>
				</div>
				<div class="bottom">
					<div class="number">{{resetBankNum(item.bank_card)}}</div>
				</div>
			</div>
			<div class="add-bank" @click="bindBank">
				<!-- <div style="display: flex;justify-content: center;"> -->
					<i class="el-icon-circle-plus-outline"></i>
					<div class="title">{{$t('bindbankcard')}}</div>
				<!-- </div> -->
				
			</div>

		</div>
		<!-- <div @click="bindBank" style="color: red;">绑定银行卡</div> -->

		<!-- 绑定银行卡弹窗 -->
		<PopupCommon ref="popupBindBank" customClassName="textarea-modal" title=" " :visible.sync="visible"
			:width="'556px'">
			<div :slot="'title'" class="pop-title">{{$t('bindbankcard')}}</div>
			<!-- <div style="width:556px;height:1px;background: ;"></div> -->
			<div :slot="'content'" class="pop-content">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item :label="$t('bankcard')" prop="bankId">
						<el-select @change="selectChange" v-model="ruleForm.bankId" :placeholder="$t('changebankcard')" popper-class="reset-popper-bg-color">
							<span v-for="(item1, index) in bankNameList" :key="index">
							  <el-option
							    :label="item1.bank_name"
							    :value="item1.id"
							  ></el-option>
							</span>
						</el-select>
						<!-- <el-cascader
						  :options="bankNameList"
						  :props="{ multiple: false }"
									placeholder="请选择银行卡"
									 popper-class="reset-popper-bg-color"
									 @change="selectChange"
									 v-model="claimValue"
						  clearable
						></el-cascader> -->
					</el-form-item>
					<el-form-item :label="$t('bankcardnum')" prop="bankNumber">
						<el-input v-model="ruleForm.bankNumber" clearable :placeholder="$t('inputbankcardnum')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('name')" prop="name">
						<el-input v-model="ruleForm.name" :placeholder="$t('inputname')" style="padding-right: 20px;"></el-input>
					</el-form-item>
					<el-form-item :label="$t('IDnum')" prop="idCard">
						<el-input v-model="ruleForm.idCard" :placeholder="$t('inputIDnum')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('mobile')" prop="phone">
						<el-input v-model="ruleForm.phone" :placeholder="$t('inputmobile')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('verificationcode')" prop="vcode">
						<el-input class="code" style="width: 200px;" v-model="ruleForm.vcode"
							:placeholder="$t('inputverificationcode')">
							<el-button slot="append" type="primary" @click="changeCode" :disabled="vCodeLoading">
								{{ vCodeBtnText }}
							</el-button>
							<!-- <el-button type="primary" @click="changeCode" :disabled="vCodeLoading">{{ vCodeBtnText }}</el-button> -->
						</el-input>
						<!-- <el-button type="primary" @click="submitForm('ruleForm')">创建</el-button> -->

					</el-form-item>
				</el-form>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<div class="cancel" @click="cancelBindBank">{{$t('recommend.cancel')}}</div>
				<div class="confirm" @click="confirmBindBank">{{$t('recommend.confirm')}}</div>
			</div>
		</PopupCommon>

		<!-- 绑定成功弹窗 -->
		<PopupCommon ref="popupSubmit" customClassName="textarea-modal" title=" " :width="'556px'">
			<div :slot="'title'" class="pop-title">{{$t("success")}}</div>
			<!-- <div style="width:556px;height:1px;background: ;"></div> -->
			<div :slot="'content'" class="pop-content-submit">
				<Icon name="icon-7key-musicguanzhuchenggong" color="#00BEA5" width="45" height="45" />
				<div class="title">{{$t("bindsuccess")}}</div>
				<!-- <div class="desc">我们会尽快为您安排音乐人完成您的需求</div> -->
			</div>
			<div :slot="'footer'" class="pop-footer-submit">
				<div class="cancel" @click="closePop">{{$t("close")}}</div>
				<!-- <div class="confirm" @click="confirm">确认</div> -->
			</div>
		</PopupCommon>
	</div>

</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	import {
		sendVCode
	} from "@/utils/common";
	import {
		addBank,
		myInfo,
		delBank,
		bankList
	} from "@/api/Mine";
	export default {
		props: {},
		computed: {},
		components: {
			PopupCommon
		},
		data() {
			return {
				visible: false,
				ruleForm: {
					bankId: '',
					// bankCard: 0,
					bankNumber: '',
					name: '',
					idCard: '',
					phone: '',
					vcode: '',
				},
				rules: {
					// bankId: [{}],
					// bankCard: [{
					// 	message: '请选择银行卡',
					// 	trigger: 'change'
					// }],
					bankNumber: [{
						message: this.$t("inputbankcardnum"),
						trigger: 'blur'
					}],
					name: [{
						message: this.$t("inputname"),
						trigger: 'blur'
					}],
					idCard: [{
						message: this.$t("inputIDnum"),
						trigger: 'blur'
					}],
					phone: [{
						message: this.$t("inputmobile"),
						trigger: 'blur'
					}],
					vcode: [{
						message: this.$t("placeholder") + this.$t("vCodeText"),
						trigger: 'blur'
					}],

				},
				loading: false,
				vCodeLoading: false,
				vCodeCount: 60,
				vCodeBtnText: this.$t('haveauthcode'),
				bankNameList: [],//银行列表
				infoObj: {}
			}
		},
		created() {
			this.getMyInfo()
			this.bankList()
		},
		methods: {
			sendVCode,
			// 获取银行列表
			bankList(){
				bankList({}).then((res) => {
						console.log(res, '获取银行列表');
						this.bankNameList = res.data
						// localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					})
					.catch((err) => {
						console.log(err, '我的信息失败');
					});
			},
			selectChange(e){
				console.log(e,'eeeeeeeeee')
				this.ruleForm.bankId = e
			},
			// 我的信息
			getMyInfo() {
				myInfo({}).then((res) => {
						console.log(res, '我的信息成功');
						this.infoObj = res.data
						// localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					})
					.catch((err) => {
						console.log(err, '我的信息失败');
					});
			},
			changeCode() {
				console.log(this.ruleForm, 'changeCode')
				this.sendVCode(this.telephoneCode, this.ruleForm.phone)
				this.countDown()
			},
			countDown() {
				let inter = setInterval(() => {
					if (this.vCodeCount == 0) {
						clearInterval(inter)
						this.vCodeLoading = false
						this.vCodeCount = 60
						this.vCodeBtnText = this.$t("testGetCode")
					} else {
						this.vCodeLoading = true
						this.vCodeCount--
						this.vCodeBtnText = `${this.vCodeCount}${this.$t("tryAgainInSeconds")}`
					}
				}, 1000)
			},
			// 绑定银行卡
			addBank() {
				addBank({
						bank_id: this.ruleForm.bankId, //银行卡ID
						bank_card: this.ruleForm.bankNumber, //银行卡号
						real_name: this.ruleForm.name, //真实姓名
						verificationCode: this.ruleForm.vcode, //验证码
						mobile: this.ruleForm.phone, //手机号
						idCard: this.ruleForm.idCard, //身份证号
					}).then((res) => {
						console.log(res, '绑定银行卡成功');
						if(res.status == 1){
							this.$refs.popupSubmit.open()
						}
						
						
						// this.infoObj = res.data
					})
					.catch((err) => {
						console.log(err, '绑定银行卡失败');
					});
			},
			// 删除银行卡
			delBank(bankId){
				delBank({
						userbank_id: bankId, //银行卡ID
					}).then((res) => {
						console.log(res, '删除银行卡成功');
						this.getMyInfo()
						// this.infoObj = res.data
					})
					.catch((err) => {
						console.log(err, '删除银行卡失败');
					});
			},
			resetBankNum(bankNum) {
				var str = String(bankNum)
				// console.log(str)
				var len = str.length;
				var reg = str.slice(0, 12)
				// console.log(reg, 'reg')
				return str.replace(reg, " .... .... ....")

			},
			// 打开绑定银行卡弹窗
			bindBank() {
				this.$refs.popupBindBank.open()
			},
			cancelBindBank() {
				this.$refs.popupBindBank.close()
			},
			confirmBindBank() {
				this.addBank()
				// this.$refs.popupSubmit.open()
			},
			closePop() {
				this.$refs.popupSubmit.close()
				this.$refs.popupBindBank.close()
				this.getMyInfo()
			}
		}
	}
</script>

<style lang="less" scoped>
	.main {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		color: #fff;
		justify-content: space-between;
		// margin-left: 28px;
		.add-bank{
			width: 48%;
			height: 200px;
			// margin-right: 24px;
			margin-bottom: 24px;
			background-image: url(../../../assets/images/personalCenter/add-bank-bg.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 22px;
			justify-content: center;
			cursor: pointer;
			.title{
				margin-top: 10px;
			}
		}
		.content {
			// width: 40%; 
			width: 48%;
			height: 200px;
			// margin-right: auto;
			margin-bottom: 24px;
			background-image: url(../../../assets/images/personalCenter/bind-bank-blue.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			.top {
				display: flex;
				justify-content: space-between;
				padding: 0 24px;

				.left {
					display: flex;

					.icon {
						width: 48px;
						height: 49px;
					}

					.desc {
						margin-left: 17px;

						.title {
							margin-bottom: 10px;
						}
					}
				}
				
				.relieve-bind{
					cursor: pointer;
				}
			}

			.bottom {
				padding: 0 24px;
				display: flex;
				justify-content: flex-start;

				.number {
					font-size: 40px;
					// display: flex;
					// align-items: center;
					text-align: center;
				}
			}
		}
	}

	// 绑定银行卡弹窗
	.pop-title {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}

	.pop-content {
		// max-height: 86px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;

		.demo-ruleForm {
			::v-deep .el-input__inner {
				width: 390px;
				background: @recommendMusicianListBGColor;
				border: none;
				color: @uploadFormTextColor;

				&:hover {
					border: 1px solid @popupCancelTextColor;
				}
			}

			::v-deep .el-form-item__label {
				color: @recommendMusicianListTextColor;
			}

			.code {
				::v-deep .el-input__inner {
					width: 302px;
					background: @recommendMusicianListBGColor;
					border: none;

					&:hover {
						border: 1px solid @popupCancelTextColor;
					}
				}

				::v-deep .el-form-item__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-button--primary {
					width: 86px;
					height: 38px;
					background: #3370FF;
					border-radius: 4px;
					border: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					color: #FFFFFF;
				}

				::v-deep .el-input-group__append,
				.el-input-group__prepend {
					background: rgba(0, 0, 0, 0);
					border: none;
				}
			}
		}

	}

	.pop-footer {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: center;

		.cancel {
			width: 102px;
			height: 36px;
			border-radius: 4px;
			border: 1px solid @popupCancelBorderColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			margin-right: 44px;
			background: @popupCancelBgColor;
			cursor: pointer;
		}

		.confirm {
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			cursor: pointer;
		}
	}

	// 绑定成功弹窗
	.pop-content-submit {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;

		.title {
			padding-top: 24px;
			font-size: 20px;
			font-weight: 500;
			// color: #FFFFFF;
			color: @recommendMusicianListTextColor;
			margin-bottom: 12px;
		}

		.desc {
			font-size: 14px;
			font-weight: 500;
			color: rgba(255, 255, 255, 0.6);
		}
	}

	.pop-footer-submit {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: center;

		.cancel {
			width: 102px;
			height: 36px;
			border-radius: 4px;
			border: 1px solid @popupCancelBorderColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			// margin-right: 44px;
			background: @popupCancelBgColor;
			cursor: pointer;
		}
	}
</style>
